import { useAudio } from "Components/useAudio";
import { getData } from "Pages/Play/func";
import WrongSound from '../../Assets/WrongSound.mp3';

const { createContext, useState, useEffect } = require("react");

export const TTCtx = createContext({});

const defaultState = {
  isWrong: false,
  percent: 0,
  fetched: false,
  board: [],
  cards: {},
  hands: [],
  step: 0,
  selected: null,
}

const texts = [
  "ฮัลโหลลล",
  "ลองเลือกการ์ดที่อยู่ข้างล่างสักใบสิ",
  "",
  "แล้วกดปุ่มสีเขียวเพื่อเลือกลำดับเวลาที่ถูกต้องของ 2 เหตุการณ์นี้",
  "",
  "ถ้าเลือกผิด เวลาจะถูกหัก 30 วินาที",
  "ฮั่นแน่! เมื่อกี้ให้ลองเล่นๆ นะ",
  "ต่อไปจะเอาจริงละ",
  "ลุย!!!",
]

const TTPvd = ({ children, ...props}) => {
  const [state,setState] = useState({ ...defaultState });
  const handleSetState = key => value => setState(s=>({ ...s, [key]:value }));
  const [ , wrongToggle ] = useAudio(WrongSound);

  const store = {
    ...props,
    state: [state,setState],
    texts,
    onInsert: index => () => state.step===4 && store.onNext(),
    onPush: () => state.step===4 && store.onNext(),
    onSelectedCard: id => () => {
      handleSetState('selected')(id===state.selected?null:id);
      if(state.step===2){ store.onNext(); }
    },
    onNext: () => {
      if(state.step<8){
        handleSetState('step')(state.step+1);
        if(state.step===4){ wrongToggle(); }
      } else {
        localStorage.setItem('tutor',true);
        window.location.reload();
      }
    },
    onSkip: () => handleSetState('step')(8),
  }

  useEffect(()=>{
    getData().then(result=>setState(s=>({ ...s, ...result, fetched:true })));
  }, [])

  return (<TTCtx.Provider value={store}>
    {children}
  </TTCtx.Provider>)
}

export const connectTT = Comp => props => (<TTPvd {...props}>
  <Comp {...props} />
</TTPvd>) 