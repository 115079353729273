import { createTheme, ThemeProvider } from '@material-ui/core';
import PageTutorial from 'Pages/Tutorial';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import './App.css';

import HomePage from './Pages/Home';
import PlayPage from './Pages/Play';

const theme = createTheme({
  palette:{
    primary: {
      light: '#93acbc',
      main: '#3c77ae',
      dark: '#034ea2',
    },
    secondary: {
      light: '#F27292',
      main: '#C73D5D',
      dark: '#3C77AE',
    },
    error: {
      light: '#D5999E',
      main: '#DC4E34',
      dark: '#C9252C',
    },
    success: {
      light: '#84C198',
      main: '#6E953B',
      dark: '#009057',
    },
    warning: {
      light: '#F3D15E',
      main: '#EEA320',
      dark: '#F68B33',
    },
  },
  typography: {
    h1: { fontFamily: '"Kanit", "Roboto", "Helvetica", "Arial", sans-serif', },
    h2: { fontFamily: '"Kanit", "Roboto", "Helvetica", "Arial", sans-serif', },
    h3: { fontFamily: '"Kanit", "Roboto", "Helvetica", "Arial", sans-serif', },
    h4: { fontFamily: '"Kanit", "Roboto", "Helvetica", "Arial", sans-serif', },
    h5: { fontFamily: '"Kanit", "Roboto", "Helvetica", "Arial", sans-serif', },
    h6: { fontFamily: '"Kanit", "Roboto", "Helvetica", "Arial", sans-serif', },
  },
})

const App = props => {
  return (<ThemeProvider theme={theme}>
    <BrowserRouter>
      <Switch>
        <Route path="/tutorial" component={PageTutorial} />
        <Route path="/play" component={localStorage.tutor?PlayPage:PageTutorial} />
        <Route path="/" component={HomePage} />
      </Switch>
    </BrowserRouter>
  </ThemeProvider>);
}

export default App;
