import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Container, withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom'
import menuBG from '../../Assets/MenuBG.png';

const Wrap = withStyles(theme => ({
  root: {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: '10vmin',
  },
}))(Box);

const PlayButton = withStyles(theme => ({
  root: {
    borderRadius: '15vmin',
    fontSize: '20vmin',
    fontFamily: 'Kanit',
    color: 'rgba(252,191,67,1)',
    backgroundColor: 'white',
    transition: 'all 0.25s',
    padding: '1.5vmin',
    boxShadow: '0 0 5vmin rgba(0,0,0,0.25)',
    "&:hover": {
      backgroundColor: 'white',
      transform: 'scale(1.05,1.05)',
    }
  },
}))(props => <Button component={Link} to={`/play`} {...props} />)

const HomePage = props => {
  return (<div className="Menu" style={{ backgroundImage: `url('${menuBG}')` }}>
    <Container>
      <Wrap>
        <PlayButton>
          <FontAwesomeIcon icon={faPlayCircle} />
        </PlayButton>
      </Wrap>
    </Container>
  </div>)
}

export default HomePage;