import { faBars, faForward, faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container as Ctn, Box, Button, Grid, IconButton, Typography, withStyles } from "@material-ui/core";
import Card from "Components/Card";
import Clock from "Components/Clock";
import { Content, Footer, Header, Separator } from "Pages/Play/Components";
import Progress from "Pages/Play/Progress";
import { Fragment } from "react";
import { useContext } from "react";
import bg from '../../Assets/GameplayBG.png';
import { connectTT, TTCtx } from "./Context";

const ButtonNext = withStyles(theme=>({
  root: {
    backgroundColor: '#fcbf43',
    color: 'white',
    "&:hover": {
      backgroundColor: '#d69717',
    }
  },
}))(({icon,...props})=><Button variant="contained" disableElevation startIcon={<FontAwesomeIcon icon={icon} />} {...props} />)

export const Container = withStyles(theme => ({
  root: {
    position: 'absolute',
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    backgroundImage: `url("${bg}")`,
    backgroundPosition: 'center center',
    color: 'white',
  },
}))(Box);

const ShowText = withStyles(theme=>({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.75)',
    display: 'flex',
    flexDirection: 'column',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(2),
    "&>*:not(:last-child)": {
      marginRight: theme.spacing(1),
    }
  },
  body: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fcbf43',
    cursor: 'pointer',
    textAlign: 'center',
  },
}))(({ classes, text, onNext, onSkip, ...props }) => {
  const store = useContext(TTCtx);
  const [state] = store.state;
  return (<div className={classes.root}>
    { state.step<8 && (<div className={classes.actions}>
      <ButtonNext icon={faPlay} onClick={onNext}>Next</ButtonNext>
      <ButtonNext icon={faForward} onClick={onSkip}>Skip</ButtonNext>
    </div>) }
    <div className={classes.body} onClick={onNext}>
      <Ctn maxWidth="md">
        <Typography variant="h1">
          <b>{text}</b>
        </Typography>
      </Ctn>
    </div>
  </div>)
})

const PageTutorial = props => {
  const { onInsert, onPush, onSelectedCard, onNext, onSkip, texts, ...store } = useContext(TTCtx);
  const [state] = store.state;

  return (<Container>
    {
      state.fetched
        ? (<>
          <Header>
            <Clock isWrong={state.step===5} time={state.step>=5?210:240} />
            <Progress percent={state.percent} />
            <IconButton color="inherit" edge="start"><FontAwesomeIcon icon={faBars} /></IconButton>
          </Header>
          <Content>
            <Grid container justifyContent="center" spacing={4}>
              {state.board.map((id, index) => state.cards[id] && (<Fragment key={id}>
                { state.selected && <Separator onClick={onInsert(index)} />}
                <Card data={state.cards[id]} />
              </Fragment>))}
              {state.selected && <Separator onClick={onPush} />}
            </Grid>
          </Content>
          <Footer>
            <Grid container justifyContent="center" spacing={4}>
              {state.hands.map(id => state.cards[id] && (<Card
                className="bottom"
                data={state.cards[id]}
                onClick={onSelectedCard(id)}
                selected={state.selected === id}
                key={id}
              />))}
            </Grid>
          </Footer>
        </>)
        : null
    }
    { texts[state.step] && <ShowText text={texts[state.step]} onNext={onNext} onSkip={onSkip} /> }
  </Container>);
}

export default connectTT(PageTutorial);