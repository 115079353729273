import data from "../../data.json";

export const getData = async (used = []) => {
  const toArrays = Object.values(data.Sheet1).map((item, index) => {
    const key = `tb${index}`;
    const [a, b, c, d] = Object.values(item);
    return { [key]: [b, a, c, d] };
  });
  const cards = Object.assign({}, ...toArrays);

  const keys = Object.keys(cards).filter((key) => !used.includes(key));
  const hands = [...Array(4).keys()].map(() => {
    const [key] = keys.splice(Math.floor(Math.random() * keys.length), 1);
    return key;
  });
  const board = keys.splice(Math.floor(Math.random() * keys.length), 1);
  return { cards, hands, board };
};
