import clsx from 'clsx';
import { faArrowCircleDown, faRedo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Backdrop, Box, Divider, Grid, IconButton, Paper, Slide, Typography, withStyles } from "@material-ui/core";


export const Header = withStyles(theme => ({
  root: {
    display: 'flex',
    padding: theme.spacing(3),
    alignItems: 'center',
  },
}))(Box);

export const Content = withStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
}))(Box);

export const Footer = withStyles(theme => ({
  root: {
    overflow: 'hidden',
  },
}))(Box);

const separatorWidth = 56;
export const Separator = withStyles(theme => ({
  box: {
    width: 2,
    height: 225,
    backgroundColor: 'white',
    position: 'relative',
    cursor: 'pointer',
  },
  icon: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: separatorWidth,
    height: separatorWidth,
    top: -(separatorWidth),
    left: -(separatorWidth / 2),
    backgroundColor: 'white',
    color: theme.palette.success.main,
    fontSize: 48,
    "&:hover": {
      backgroundColor: 'white',
      color: theme.palette.success.dark,
    },
  },
  fa: {
    position: 'absolute',
  },
}))(({ classes, ...props }) => {
  return (<Grid item>
    <Slide in={true} direction="down">
      <Box className={classes.box} {...props}>
        <IconButton className={classes.icon}>
          <FontAwesomeIcon className={classes.fa} icon={faArrowCircleDown} />
        </IconButton>
      </Box>
    </Slide>
  </Grid>)
})


const ResultBox = ({label,value}) => (<Box display="flex">
  <Typography variant="h5">{ label }</Typography>
  <Box flexGrow={1} />
  <Typography variant="h5">{ value }</Typography>
</Box>);
const convertToTime = remain => `${Math.floor(remain/60)}:${remain%60<10 ? '0'+remain%60 : remain%60 }`;

const EndGameBox = withStyles(theme=>({
  paper: {
    width: 420,
    height: 420,
    color: 'white',
    border: 'solid 8px white',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.success.main,
    '&.lose': {
      backgroundColor: theme.palette.error.main,
    },
  },
  divider: {
    backgroundColor: 'white',
    width: '100%',
  }
}))(({ classes, state, remain, label, ...props }) => {
  return (<Backdrop open={true} style={{zIndex:1}}>
    <Paper className={clsx(classes.paper,props.className)}>
      <Box textAlign="center">
        <Typography variant="h2"><b>{label}</b></Typography>
      </Box>
      <Divider className={classes.divider} />
      <Box>
        <ResultBox label="จำนวนการ์ดที่เลือกถูก" value={state.board.length-1} />
        <ResultBox label="จำนวนการ์ดที่เลือกผิด" value={state.wrong} />
        <ResultBox label="เวลาที่เหลือ" value={convertToTime(remain)} />
      </Box>
      <Divider className={classes.divider} />
      <Box display="flex" justifyContent="center">
        <IconButton color="inherit" onClick={props.onReset}>
          <FontAwesomeIcon size="2x" icon={faRedo} />
        </IconButton>
      </Box>
    </Paper>
  </Backdrop>)
})

const getWinLabels = wrong => {
  switch(wrong){
    case 0:
      return 'เฉียบ !!';
    case 1:
    case 2:
      return 'แก๋ว !!';
    case 3:
    case 4:
      return 'คือดีย์';
    default:
      return 'ก็ดี ก็ดี'
  }
}
export const NextStage = props => <EndGameBox {...props} label={getWinLabels(props.state.wrong)} />;

export const LoseStage = props => <EndGameBox {...props} className="lose" label="บ้ง !!" />;