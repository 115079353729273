import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Dialog, DialogTitle, DialogContent, Grid, Grow, Typography, withStyles, Button, DialogActions, IconButton, Slide } from "@material-ui/core";
import clsx from 'clsx';
import { useState, forwardRef } from "react";


const cardWidth = 150;
const CardContainer = withStyles(theme=>({
  root: {
    position: 'relative',
    width: cardWidth,
    height: cardWidth / 2 * 3,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[4],
    padding: 4,
    boxSizing: 'border-box',
    color: theme.palette.text.primary,
    cursor: 'pointer',
    display: 'flex',
    borderRadius: 4,
    flexDirection: 'column',
    "&.bottom": {
      transform: 'translateY(50%)',
      transition: 'all 0.25s',
      "&:hover": {
        transform: 'translateY(0%)',
        zIndex: 1,
      },
    },
    "&.selected": {
      transform: 'translateY(0%)',
    },
  },
}))(Box);
const CardImage = withStyles(theme=>({
  root: {
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
    borderRadius: 2,
    "&:after": {
      content: '""',
      display: 'block',
      paddingTop: 'calc(100%)',
    },
    "& img": {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
}))(Box);
const CardContent = withStyles(theme=>({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingTop: theme.spacing(1),
    textAlign: 'center',
    fontFamily: 'Tahoma',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiTypography-root': {
      lineHeight: 1.2,
    },
  },
}))(Box);
const View = withStyles(theme=>({
  root: {
    position: 'absolute',
    top: 2,
    right: 4,
    color: 'white',
  },
}))(props=>(<IconButton size="small" {...props}>
  <FontAwesomeIcon size="sm" icon={faEye} />
</IconButton>));

const Transition = forwardRef((props,ref)=><Slide ref={ref} direction="up" {...props} />)

const Card = ({ data, className, selected, showTime, ...props}) => {
  const newClassName = [className];
  if(selected){
    newClassName.push('selected');
  }
  
  const [open,setOpen] = useState(false);
  const handleOpen = o => () => setOpen(o);

  return (<>
    <Grow in={true}>
      <Grid item>
        <CardContainer className={clsx(...newClassName)} selected={selected} {...props}>
          <CardImage>
            <img src={ data[3] || `https://picsum.photos/seed/picsum/200/300`} alt={data[0]} />
          </CardImage>
          <CardContent>
            <Typography variant="caption" color="textSecondary" style={{marginBottom:4}}>{data[2]}</Typography>
            <Typography variant="body1">
              <strong>
                {data[0]}
                { showTime&&` (${data[1]})` }
              </strong>
            </Typography>
          </CardContent>
          <View onClick={handleOpen(true)} />
        </CardContainer>
      </Grid>
    </Grow>
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleOpen(false)}
      TransitionComponent={Transition}
    >
      <DialogTitle>
        <Typography variant="h4" component="span"><b>{data[0]}</b></Typography>
      </DialogTitle>
      <DialogContent>
        <img style={{width:'100%'}} src={data[3] || `https://picsum.photos/seed/picsum/200/300`} alt="full" />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  </>)
}

export default Card;