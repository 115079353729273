import React from 'react';
import { Backdrop, CircularProgress, Grid, IconButton } from '@material-ui/core';

import Clock from 'Components/Clock';
import Card from 'Components/Card';
import Progress from './Progress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { getData } from './func';
import { Header, Content, Footer, Separator, NextStage, LoseStage } from './Components';
import { useAudio } from 'Components/useAudio';

import CorrectSound from '../../Assets/CorrectSound.mp3';
import WrongSound from '../../Assets/WrongSound.mp3';

const initState = {
  fetching: true,
  cards: {},
  itv: null,
  board: [],
  hands: [],
  selected: null,
  used: [],
  wrong: 0,
}

const HomePage = props => {
  const [ , correctToggle ] = useAudio(CorrectSound);
  const [ , wrongToggle ] = useAudio(WrongSound);
  const config = {
    limit: 240,
    target: 4,
  };
  const [state, setState] = React.useState({
    fetching: true,
    cards: {},
    start: Date.now(),
    now: Date.now(),
    itv: null,
    board: [],
    hands: [],
    selected: null,
    used: [],
    stage: 1,
    wrong: 0,
  });
  const [isWrong,setIsWrong] = React.useState(false);


  const getSec = () => {
    const seconds = config.limit - Math.floor((state.now - state.start) / 1000);
    return seconds <= 0 ? 0 : seconds;
  }
  const handleSelectedCard = (id) => () => setState(s => ({ ...s, selected: (s.selected === id ? null : id) }));
  const handleInsert = (index) => () => setState(s => {
    const correct = index === 0
      ? s.cards[s.board[index]][1] >= s.cards[s.selected][1]
      : (s.cards[s.board[index]][1] >= s.cards[s.selected][1]) && s.cards[s.board[index - 1]][1] <= s.cards[s.selected][1];
    if (correct) {
      let board = [...s.board];
      let hands = [...s.hands].filter(h => h !== s.selected);
      board.splice(index, 0, s.selected);
      correctToggle();
      return { ...s, board, hands, selected: null };
    } else {
      wrongToggle();
      setIsWrong(true);
      const newStart = new Date(s.start);
      newStart.setSeconds(newStart.getSeconds() - 30);
      return { ...s, start: newStart.getTime(), selected: null, wrong: s.wrong + 1 };
    }
  })
  const handlePush = () => setState(s => {
    const correct = s.cards[s.board[s.board.length - 1]][1] < s.cards[s.selected][1];
    if (correct) {
      let board = [...s.board];
      let hands = [...s.hands].filter(h => h !== s.selected);
      board.push(s.selected);
      correctToggle();
      return { ...s, board, hands, selected: null };
    } else {
      wrongToggle();
      setIsWrong(true);
      const newStart = new Date(s.start);
      newStart.setSeconds(newStart.getSeconds() - 30);
      return { ...s, start: newStart.getTime(), selected: null, wrong: s.wrong + 1 };
    }
  });
  const handleNextStage = async () => {
    setState(s => ({
      ...s,
      ...initState,
      start: Date.now(),
      now: Date.now(),
      stage: s.stage + 1,
    }))
    const result = await getData(state.used);
    setState(s => ({ ...s, ...result, fetching: false }));
  }

  React.useEffect(() => {
    getData(state.used).then(result => {
      setState(s => ({ ...s, ...result, fetching: false }));
    });
  }, [state.used])


  React.useEffect(() => {
    const timecountdown = setInterval(() => {
      setState(s => ({ ...s, now: Date.now() }));
    }, 1000);
    setState(s => ({ ...s, itv: timecountdown }));
  }, [state.stage, setState])


  React.useEffect(() => {
    const sec = config.limit - Math.floor((state.now - state.start) / 1000);
    if (sec <= 0 || state.board.length === 5) {
      clearInterval(state.itv);
    }
  }, [state.now, state.itv, config.limit, state.start, state.board.length]);

  React.useEffect(()=>{
    if(isWrong){
      setTimeout(()=>{
        setIsWrong(false);
      }, 1000)
    }
  }, [isWrong])


  const [keydetect,setKeydetect] = React.useState("");
  const isSuperman = keydetect.includes('ArrowUpArrowUpArrowDownArrowDownArrowLeftArrowRightArrowLeftArrowRightKeyAKeyBEnter');
  React.useEffect(()=>{
    document.addEventListener('keyup', e => setKeydetect(c=>(c+e.code)))
  }, [])


  return (<div className="App">
    {
      !state.fetching
        ? (<>
          <Header>
            <Clock isWrong={isWrong} time={getSec()} />
            <Progress percent={(state.board.length - 1) / config.target * 100} />
            <IconButton color="inherit" edge="start"><FontAwesomeIcon icon={faBars} /></IconButton>
          </Header>
          <Content key={`content-${state.stage}`}>
            <Grid container justifyContent="center" spacing={4}>
              {state.board.map((id, index) => state.cards[id] && (<React.Fragment key={id}>
                { state.selected && <Separator onClick={handleInsert(index)} />}
                <Card showTime={isSuperman} data={state.cards[id]} />
              </React.Fragment>))}
              {state.selected && <Separator onClick={handlePush} />}
            </Grid>
          </Content>
          <Footer key={`footer-${state.stage}`}>
            <Grid container justifyContent="center" spacing={4}>
              {state.hands.map(id => state.cards[id] && (<Card
                showTime={isSuperman}
                className="bottom"
                data={state.cards[id]}
                onClick={handleSelectedCard(id)}
                selected={state.selected === id}
                key={id}
              />))}
            </Grid>
          </Footer>
          { state.board.length >= 5 && <NextStage state={state} remain={getSec()} onReset={handleNextStage} />}
          { getSec() <= 0 && <LoseStage state={state} remain={getSec()} onReset={handleNextStage} />}
        </>)
        : (<Backdrop open={true} style={{ zIndex: 1 }}>
          <CircularProgress size={64} color="inherit" />
        </Backdrop>)
    }
  </div>)
}

export default HomePage;