import React from 'react';
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grow, Typography } from "@material-ui/core";

const Clock = ({ time, isWrong, ...props }) => {
  return (<Box alignItems="center" display="flex" style={{width:160}}>
    <FontAwesomeIcon size="3x" icon={faClock} />
    <Box pl={2} />
    <Box textAlign="center" flexGrow={1} position="relative">
      { isWrong && (<Grow in={true}>
        <Box display="flex" justifyContent="center" alignItems="center" position="absolute" top="75%" left="0" width="100%" height="100%" style={{textShadow:'0 0 4px rgba(0,0,0,1)'}}>
          <Typography variant="h4" color="secondary">-O:30</Typography>
        </Box>
      </Grow>) }
      <Typography variant="h3">
        {Math.floor(time/60)}:{ (time%60)<10 && '0' }{time%60}
      </Typography>
    </Box>
  </Box>)
}

export default Clock;