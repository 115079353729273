import { Box, LinearProgress, withStyles } from '@material-ui/core';
import React from 'react';

const LinearStyle = withStyles(theme=>({
  root: {
    height: theme.spacing(5),
    borderRadius: theme.spacing(2.5),
    backgroundColor: 'white',
    "& .MuiLinearProgress-bar": {
      backgroundColor: theme.palette.success.main,
    }
  },
}))(LinearProgress);

const Progress = ({ percent, ...props }) => {
  return (<Box flexGrow={1} mx={5}>
    <LinearStyle variant="determinate" value={Math.ceil(percent)} />
  </Box>)
}

export default Progress;